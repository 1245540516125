import React from "react";
import styled from "styled-components";

export const YoutubeIcon = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 86.43"
    width={18}
    height={18}
  >
    <defs>
      <YtStyle />
    </defs>
    <path
      fill="#fff"
      className="cls-1"
      d="M121.63,18.65s-1.2-8.47-4.9-12.19c-4.67-4.89-9.91-4.92-12.31-5.21C87.24,0,61.43,0,61.43,0h0s-25.8,0-43,1.25c-2.4.29-7.63.31-12.31,5.21C2.4,10.18,1.22,18.65,1.22,18.65A187.15,187.15,0,0,0,0,38.55v9.31a187.65,187.65,0,0,0,1.22,19.9S2.42,76.23,6.09,80c4.68,4.9,10.82,4.74,13.57,5.26,9.83.94,41.78,1.22,41.78,1.22s25.83,0,43-1.27c2.41-.29,7.64-.32,12.32-5.21,3.69-3.72,4.89-12.2,4.89-12.2a187.15,187.15,0,0,0,1.22-19.9V38.54a189.26,189.26,0,0,0-1.25-19.9Z"
    />
    <polygon
      className="cls-2"
      points="48.71 59.16 48.71 24.63 81.9 41.95 48.71 59.16 48.71 59.16"
    />
  </svg>
);

const YtStyle = styled.style`
  .cls-1,
  .cls-2 {
    fill-rule: evenodd;
  }
  .cls-2 {
    fill: #fff;
  }
`;
