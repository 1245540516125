import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  SpotifyIcon,
  InstagramIcon,
  YoutubeIcon,
  HamburgerMenuIcon,
  TiktokIcon,
} from "./icons/index.js";
import { motion, AnimatePresence } from "framer-motion";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const DJPhoto05 = require("./images/DJ-PhotoDarker-05.jpg");
const DJPhoto04 = require("./images/DJ-PhotoDarker-04.jpg");
const DJPhoto03 = require("./images/DJ-PhotoDarker-03.jpg");
// const DJPhotoSmallest = require("./images/DJ-PhotoDarker-02.jpg");
const InTheNightArtwork = require("./images/Artwork-InTheNight.png");
const MySkinArtwork = require("./images/MySkinArtwork.jpg");
const OverloadArtwork = require("./images/Overload.jpg");
const CLGArtwork = require("./images/CLG-Artwork.jpg");
const LundzLogoImageSource = require("./images/LundzLogo.png");

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-color: black;
`;

/* const TopImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: 100% 0;
  position: absolute;
  z-index: -1;
`; */

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url(${DJPhoto05});
  background-size: cover;

  @media only screen and (max-width: 1500px) {
    background-image: url(${DJPhoto04});
  }

  @media only screen and (max-width: 1200px) {
    background-image: url(${DJPhoto03});
    background-position-x: 60%;
    height: 70vh;
  }

  /* @media only screen and (max-width: 900px) {
    background-image: url(${DJPhoto03});
    background-position-x: 60%;
    height: 70vh;
  } */

  @media only screen and (max-width: 600px) {
    background-image: url(${DJPhoto03});
    background-position-x: 60%;
    height: 70vh;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 70vh;
  background-color: black;
  //padding-top: 20px;
  @media only screen and (max-width: 900px) {
    padding-bottom: 30px;
  }
`;

const HorizontalContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;

  & > * {
    flex: 1;
  }

  @media only screen and (max-width: 600px) {
    align-items: center;
    justify-content: center;
    width: 100%;
    // margin-top: 0;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  width: 90%;
  min-width: 450px;

  @media only screen and (max-width: 900px) {
    padding-top: 40px;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 20px;
    min-width: 300px;
  }
`;

const NavBar = styled.div`
  position: fixed;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
`;

const NavBarButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 5;

  @media only screen and (max-width: 900px) {
    width: 60%;
  }

  @media only screen and (max-width: 600px) {
    visibility: hidden;
    flex: 0;
    width: 0;
  }
`;

const NavBarLeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding-left: 10px;
`;

const NavBarRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding-right: 10px;
`;

const NavBarButton = styled(motion.button)`
  border: none;
  outline: none;
  background: none;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 15px;
  margin: 0 5px 0 5px;
  cursor: pointer;
`;

const HambrugerMenuButton = styled(NavBarButton)`
  visibility: hidden;
  @media only screen and (max-width: 600px) {
    visibility: visible;
  }
`;

const LundzLogo = styled(motion.p)`
  color: white;
  font-size: 20px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  letter-spacing: 3px;
  cursor: pointer;
  text-align: left;
  padding-left: 20px;
  @media only screen and (max-width: 900px) {
    padding-left: 10px;
    font-size: 18px;
  }
`;

const SectionText = styled.p`
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  letter-spacing: 2px;
  line-height: 25px;
  width: 85%;
  max-width: 1300px;

  @media only screen and (max-width: 600px) {
    width: 80%;
  }
`;

const InTextLink = styled.a`
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  letter-spacing: 2px;
  line-height: 25px;
  text-underline-offset: 4px;
`;

const LatestReleaseImage = styled(motion.img)`
  width: 350px;
  height: 350px;
  border-radius: 10px;

  @media only screen and (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 400px;

  @media only screen and (max-width: 600px) {
    width: 85%;
    height: 250px;
  }
`;

const StyledLink = styled(motion.a)`
  cursor: pointer;
  margin: 0 10px 0 10px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.p`
  color: white;
  font-size: 20px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
`;

const DrawerContainer = styled(motion.div)`
  position: fixed;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 160px;
  height: 100vh;
  background-color: black;
  right: 0;
  top: 0;
  visibility: hidden;
  padding-top: 80px;
  padding-left: 50px;

  & > * {
    margin-top: 10px;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 150px;
  width: 100%;

  /* & > *:first-child {
    display: flex;
    flex: 1;
    align-items: flex-start;
  }

  & > *:last-child {
    display: flex;
    flex: 1;
    align-items: flex-end;
  } */
`;

/* const LundzLogoContainer = styled.div`
  display: flex;
  // justify-content: center;
  // align-items: center;
`; */

const LundzLogoImage = styled.img`
  width: 50px;
  height: 50x;
`;

const ContactLink = styled(motion.a)`
  color: black;
  font-size: 14px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  letter-spacing: 2px;
  line-height: 25px;
  text-underline-offset: 5px;
  text-align: center;
`;

const App = () => {
  const [menuBlack, setMenuBlack] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const refContact = useRef(null);
  const refMusic = useRef(null);
  const refHome = useRef(null);
  const refAbout = useRef(null);

  const executeScroll = (ref) =>
    ref.current.scrollIntoView({ behavior: "smooth" });

  const NavOptionAnimation = {
    scale: 1.05,
    letterSpacing: "3px",
    transition: { duration: 0.5 },
  };

  const SomeButtonAnimation = {
    scale: 1.2,
    transition: { duration: 0.3 },
  };

  useScrollPosition(({ prevPos, currPos }) => {
    console.log(window.innerWidth);

    let breakpoint = -500;

    if (window.innerWidth >= 900) {
      breakpoint = -800;
    }

    if (currPos.y <= breakpoint) {
      setMenuBlack(true);
    } else {
      setMenuBlack(false);
    }
  });

  const navBarColor = menuBlack ? "#000" : "rgba(0, 0, 0, 0.3)";

  return (
    <Container ref={refHome}>
      <AnimatePresence>
        {drawerVisible && (
          <DrawerContainer
            initial={{ x: 160, visibility: "hidden" }}
            animate={{ x: 0, visibility: "visible" }}
            transition={{ duration: 0.3 }}
            exit={{ x: 220 }}
          >
            <NavBarButton
              whileHover={NavOptionAnimation}
              onClick={() => {
                executeScroll(refHome);
                setDrawerVisible(false);
              }}
            >
              HOME
            </NavBarButton>
            <NavBarButton
              id="id"
              whileHover={NavOptionAnimation}
              onClick={() => {
                executeScroll(refMusic);
                setDrawerVisible(false);
              }}
            >
              MUSIC
            </NavBarButton>
            <NavBarButton
              whileHover={NavOptionAnimation}
              onClick={() => {
                executeScroll(refAbout);
                setDrawerVisible(false);
              }}
            >
              ABOUT
            </NavBarButton>
            <NavBarButton
              whileHover={NavOptionAnimation}
              onClick={() => {
                executeScroll(refContact);
                setDrawerVisible(false);
              }}
            >
              CONTACT
            </NavBarButton>
          </DrawerContainer>
        )}
      </AnimatePresence>
      <NavBar style={{ background: navBarColor }}>
        <NavBarLeftContainer>
          <LundzLogo
            whileHover={NavOptionAnimation}
            onClick={() => executeScroll(refHome)}
          >
            LUNDZ
          </LundzLogo>
        </NavBarLeftContainer>

        <NavBarButtonContainer>
          <NavBarButton
            whileHover={NavOptionAnimation}
            onClick={() => executeScroll(refHome)}
          >
            HOME
          </NavBarButton>
          <NavBarButton
            id="id"
            whileHover={NavOptionAnimation}
            onClick={() => executeScroll(refMusic)}
          >
            MUSIC
          </NavBarButton>
          <NavBarButton
            whileHover={NavOptionAnimation}
            onClick={() => executeScroll(refAbout)}
          >
            ABOUT
          </NavBarButton>
          <NavBarButton
            whileHover={NavOptionAnimation}
            onClick={() => executeScroll(refContact)}
          >
            CONTACT
          </NavBarButton>
        </NavBarButtonContainer>

        <NavBarRightContainer>
          <IconContainer>
            <StyledLink
              whileHover={SomeButtonAnimation}
              target="_blank"
              href="https://open.spotify.com/artist/08Jvhuv0QCuh3rOMvUGd5m?si=zvwO8-e1THeeaV0sbiL6EQ"
              rel="noreferrer"
            >
              <SpotifyIcon />
            </StyledLink>
            <StyledLink
              whileHover={SomeButtonAnimation}
              target="_blank"
              href="https://www.instagram.com/lundzmusic/"
              rel="noreferrer"
            >
              <InstagramIcon />
            </StyledLink>
            <StyledLink
              whileHover={SomeButtonAnimation}
              target="_blank"
              href="https://www.youtube.com/channel/UCWvq5JkzNfFFHePMTjCWkMw"
              rel="noreferrer"
            >
              <YoutubeIcon />
            </StyledLink>
            <StyledLink
              whileHover={SomeButtonAnimation}
              href="https://www.tiktok.com/@iamlundz"
              target="_blank"
            >
              <TiktokIcon />
            </StyledLink>
          </IconContainer>
          <HambrugerMenuButton
            whileHover={NavOptionAnimation}
            onClick={() => setDrawerVisible(!drawerVisible)}
          >
            <HamburgerMenuIcon />
          </HambrugerMenuButton>
        </NavBarRightContainer>
      </NavBar>
      <ImageContainer>{/*  <TopImage src={DJPhoto} /> */}</ImageContainer>
      <SubContainer ref={refMusic}>
        <HorizontalContainer>
          <SectionContainer>
            <SectionText
              style={{
                textAlign: "center",
                marginBottom: "30px",
              }}
            >
              OUT ON ALL PLATFORMS
            </SectionText>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://presave.io/t/lundzoverload"
            >
              <LatestReleaseImage
                initial={{ scale: 0.97 }}
                animate={{ scale: [1, 0.97, 1] }}
                transition={{
                  type: "spring",
                  mass: 50,
                  repeat: Infinity,
                  duration: 2,
                }}
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.5 },
                }}
                src={OverloadArtwork}
              />
            </a>
          </SectionContainer>
          <SectionContainer>
            <SectionText
              style={{
                textAlign: "center",
                marginBottom: "30px",
              }}
            >
              MOST POPULAR RELEASES
            </SectionText>
            <StyledIframe
              style={{ borderRadius: "12px" }}
              src="https://open.spotify.com/embed/artist/08Jvhuv0QCuh3rOMvUGd5m?utm_source=generator"
              frameBorder="0"
              // allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="eager"
            ></StyledIframe>
          </SectionContainer>
        </HorizontalContainer>
      </SubContainer>
      <SubContainer ref={refAbout}>
        <StyledTitle>ABOUT</StyledTitle>
        <SectionText>
          Lundz is a norwegian artist, producer & DJ. He is writing, composing
          and producing a variety of different subgenres within House, his
          repertoire stretches from Deep House,Tech House to a more techno
          driven vibe.
        </SectionText>
        <SectionText>
          His latest release{" "}
          <InTextLink target="_blank" href="https://presave.io/t/lundzoverload">
            Overload
          </InTextLink>{" "}
          is signed on Slagelhag Records, a sub-label of Actuation Music. His
          previous releases which have been played on Norwegian main radio NRK
          mp3 are:{" "}
          <InTextLink
            target="_blank"
            href="https://open.spotify.com/track/1ZzMHIi2GW1xuxYaGW0oJx?si=58db213cb5d34a8c"
          >
            Can't Let Go
          </InTextLink>
          ,{" "}
          <InTextLink
            target="_blank"
            href="https://open.spotify.com/track/0LnvQaJL2N4nMWGLVVjXfM?si=94da397779064aa4"
          >
            My Skin
          </InTextLink>
          ,{" "}
          <InTextLink
            target="_blank"
            href="https://open.spotify.com/track/3J0yS3Pc9a7lUV4UxyWNjT?si=c847e0bf9c2f4b0d"
          >
            In The Night
          </InTextLink>
          ,{" "}
          <InTextLink
            target="_blank"
            href="https://distrokid.com/hyperfollow/lundz/my-vibration"
          >
            My Vibration
          </InTextLink>
          ,{" "}
          <InTextLink
            target="_blank"
            href="https://distrokid.com/hyperfollow/lundz/losing-control"
          >
            Losing Control
          </InTextLink>{" "}
          and{" "}
          <InTextLink
            target="_blank"
            href="https://distrokid.com/hyperfollow/lundz/drive-all-night"
          >
            Drive All Night
          </InTextLink>
          .
        </SectionText>
        <SectionText>
          The 29 year old Norwegian guy named August, is self-taught in playing
          the Piano from his youth. With a lot of musical ideas, he started to
          think about how to actually bring these ideas to life. After thinking
          about starting producing music for several years, he finally made the
          choice to deep dive into it in 2020, and has since then kept on going.
        </SectionText>
        <SectionText>
          After finishing a masters degree in Civil Engineering within Computer
          Science, and then entering the corporate world, he immediately felt
          like this was not the right path for him. While thinking about
          starting producing music for several years before that, he finally
          made the choice to deep dive into it in 2020, and has since then kept
          on going.
        </SectionText>
      </SubContainer>
      <SubContainer style={{ backgroundColor: "whitesmoke" }} ref={refContact}>
        <StyledTitle style={{ color: "black", marginBottom: "30px" }}>
          CONTACT
        </StyledTitle>
        <ContactLink
          rel="noreferrer"
          target="_blank"
          style={{ color: "black" }}
          href="https://ig.me/m/Lundzmusic"
          whileHover={NavOptionAnimation}
        >
          SEND ME A MESSAGE ON INSTAGRAM
        </ContactLink>
        <SectionText style={{ color: "black", textAlign: "center" }}>
          OR
        </SectionText>
        <ContactLink
          rel="noreferrer"
          target="_blank"
          style={{ color: "black" }}
          href="https://mail.google.com/mail/?view=cm&fs=1&to=lundzmusic@gmail.com"
          whileHover={NavOptionAnimation}
        >
          SEND AN EMAIL
        </ContactLink>
      </SubContainer>
      <Footer>
        <LundzLogoImage src={LundzLogoImageSource} />
        <SectionText
          style={{ color: "white", fontSize: "14px", textAlign: "center" }}
        >
          🎵 MADE BY LUNDZMUSIC 🎵
        </SectionText>
      </Footer>
    </Container>
  );
};

export default App;
