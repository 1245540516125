import React from "react";
import styled from "styled-components";

const StyledHamburgerMenuIcon = styled.svg`
  visibility: hidden;
  width: 0;
  @media only screen and (max-width: 600px) {
    visibility: visible;
    width: 18px;
  }
`;

export const HamburgerMenuIcon = () => (
  <StyledHamburgerMenuIcon
    xmlns="http://www.w3.org/2000/svg"
    // shapeRendering="geometricPrecision"
    // textRendering="geometricPrecision"
    // imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 512 351.67"
    width={18}
  >
    <path
      fillRule="nonzero"
      fill="#fff"
      d="M0 0h512v23.91H0V0zm0 327.76h512v23.91H0v-23.91zm0-163.88h512v23.91H0v-23.91z"
    />
  </StyledHamburgerMenuIcon>
);
